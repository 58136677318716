* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

table,
td,
th {
  border-collapse: collapse;
  border: 1px solid black;
}

label {
  display: block;
}

.leftPlayer {
  color: lightgray;
  text-decoration: line-through;
}

.foldedPlayer {
  text-decoration: line-through;
}

.currentActor {
  font-weight: bolder;
}
